<template>
    <div>
        <div id="container">
            <ts-preview-pdf :src="src" v-if="!previewing"></ts-preview-pdf>
        </div>
    </div>
</template>
<script>
export default {
    name: 'previewPdf',
    props: ['src', 'previewing'],
    data () {
        return {}
    }
}
</script>

<style scoped>
#container iframe {
    width: 100%;
    height: 70vh;
}
#container i {
    margin-top: 32vh;
}
</style>
