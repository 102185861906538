<template>
    <div>
        <ts-page-title
            :title="$t('summaryReportPensionFundAndInsurance.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('summaryReportPensionFundAndInsurance.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper :loading="loading">
                <div class="row">
                    <div class="col-lg-3">
                        <div class="card border-0 bg-gray-900 mb-3">
                            <div class="card-body">
                                <div class="text-gray-500">
                                    <b>{{
                                        $t(
                                            'summaryReportPensionFundAndInsurance.filterCriteria'
                                        )
                                    }}</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="form-label col-label required">{{
                                $t(
                                    'summaryReportPensionFundAndInsurance.asOfDate'
                                )
                            }}</label>
                            <DatePicker
                                v-model="as_of_date"
                                placement="bottom-end"
                                style="width: 100%"
                                :transfer="true"
                                format="dd-MM-yyyy"
                                @on-change="onChangeTransactionDate"
                                :class="{
                                    'is-invalid': errors.has('as_of_date')
                                }"
                            ></DatePicker>
                            <div
                                class="text-danger"
                                v-if="errors.has('as_of_date')"
                            >
                                {{ errors.first('as_of_date') }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="form-label col-label">{{
                                $t(
                                    'summaryReportPensionFundAndInsurance.branch'
                                )
                            }}</label>
                            <ts-branch-filter
                                @filter="value => (branch_id = value)"
                                :isPlaceholder="true"
                            >
                            </ts-branch-filter>
                        </div>
                        <div
                            class="tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3"
                        >
                            <b-dropdown>
                                <template v-slot:button-content>
                                    <span class="inline-block">{{
                                        $t('payslip.export')
                                    }}</span>
                                </template>
                                <b-dropdown-item
                                    href="#"
                                    @click.prevent="exportExcel"
                                >
                                    <i
                                        class="fas fa-file-excel tw-text-green-500"
                                    ></i>
                                    {{ $t('payslip.excel') }}
                                </b-dropdown-item>
                                <b-dropdown-item @click="exportPdf">
                                    <i
                                        class="fas fa-file-pdf tw-text-red-500"
                                    ></i>
                                    {{ $t('payslip.pdf') }}
                                </b-dropdown-item>
                            </b-dropdown>
                            <ts-button
                                color="primary"
                                :waiting="viewing"
                                @click="fetchData"
                            >
                                {{ $t('view') }}
                            </ts-button>
                        </div>
                    </div>
                    <div class="col-lg-9">
                        <div style="max-height: 75vh">
                            <Table
                                :columns="columns"
                                :data="resources"
                                size="small"
                                :loading="loading"
                                class="tw-rounded-md"
                                max-height="700"
                                :show-summary="true"
                                :summary-method="handleSummary"
                                stripe
                            >
                                <template
                                    slot-scope="{ row }"
                                    slot="employee_pension_fund"
                                >
                                    <strong>
                                        {{
                                            formatNumber(
                                                row.employee_pension_fund
                                            )
                                        }}
                                    </strong>
                                </template>
                                <template
                                    slot-scope="{ row }"
                                    slot="total_pension_fund"
                                >
                                    <strong>
                                        {{
                                            formatNumber(row.total_pension_fund)
                                        }}
                                    </strong>
                                </template>
                                <template slot-scope="{ row }" slot="insurance">
                                    <strong>
                                        {{ formatNumber(row.insurance) }}
                                    </strong>
                                </template>
                                <template
                                    slot-scope="{ row }"
                                    slot="pension_fund_balance"
                                >
                                    <strong>
                                        {{
                                            formatNumber(
                                                row.pension_fund_balance
                                            )
                                        }}
                                    </strong>
                                </template>
                                <template
                                    slot-scope="{ row }"
                                    slot="employer_pension_fund"
                                >
                                    <strong>
                                        {{
                                            formatNumber(
                                                row.employer_pension_fund
                                            )
                                        }}
                                    </strong>
                                </template>
                            </Table>
                            <div
                                class="tw-flex tw-items-center tw-justify-end tw-p-4"
                            >
                                <ts-i-pagination
                                    v-model="pagination"
                                    @navigate="fetchData"
                                ></ts-i-pagination>
                            </div>
                            <Modal
                                v-model="showForm"
                                draggable
                                sticky
                                scrollable
                                :mask="false"
                                :footer-hide="true"
                                :z-index="1022"
                                width="1100px"
                                max-height="1500"
                                :title="$t('payslip.previewPdf')"
                            >
                                <div id="container">
                                    <i
                                        class="fa fa-spinner fa-spin fa-2x"
                                        v-if="previewing"
                                    >
                                    </i>
                                    <preview-pdf
                                        :src="src"
                                        :previewing="previewing"
                                    />
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import PreviewPdf from './preview-pdf.vue'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'

export default {
    name: 'summaryReportPensionAndInsurance',
    components: {
        PreviewPdf
    },
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            showForm: false,
            previewing: false,
            viewing: false,
            src: '',
            as_of_date: moment().format('DD-MM-YYYY'),
            branch_id: null
        }
    },
    computed: {
        ...mapState('report/summaryReportPensionFundAndInsurance', [
            'resources',
            'pagination'
        ]),
        ...mapGetters(['formatNumber', 'formatCurrencyWithCode']),
        columns () {
            return [
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.branch'
                    ),
                    width: '130px',
                    key: 'branch_name_en',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.cardId'
                    ),
                    width: '160px',
                    key: 'card_id',
                    align: 'center',
                     sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.asOfDate'
                    ),
                    width: '160px',
                    key: 'as_of_date',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.employeeName'
                    ),
                    width: '170px',
                    key: 'employee_name_en',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.employeePension'
                    ),
                    width: '170px',
                    key: 'employee_pension_fund',
                    slot: 'employee_pension_fund',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.companyPension'
                    ),
                    key: 'employer_pension_fund',
                    slot: 'employer_pension_fund',
                    width: '170px',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.totalPension'
                    ),
                    key: 'total_pension_fund',
                    width: '160px',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.insurance'
                    ),
                    key: 'insurance',
                    width: '160px',
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t(
                        'summaryReportPensionFundAndInsurance.pensionbalance'
                    ),
                    key: 'pension_fund_balance',
                    width: '160px',
                    align: 'center',
                    sortable: true
                }
            ]
        }
    },
    methods: {
        ...mapActions('report/summaryReportPensionFundAndInsurance', [
            'getFormViewData'
        ]),
        async fetchResource (attributes) {
            this.loading = true
            let response = await this.getFormViewData({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        handleSummary ({ columns, data }) {
            const sums = {}
            columns.forEach((column, index) => {
                const key = column.key
                if (index === 0) {
                    sums[key] = {
                        key,
                        value: this.$t('loanRepayment.total')
                    }
                    return
                }
                if (index === 1 || index === 2 || index === 3) {
                    sums[key] = {
                        key,
                        value: ''
                    }
                    return
                }
                const currency = data.map(item => item.currency)
                const values = data.map(item => Number(item[key]))
                if (!values.every(value => isNaN(value))) {
                    const v = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    sums[key] = {
                        key,
                        value: this.formatCurrencyWithCode(v, currency[0])
                    }
                } else {
                    sums[key] = {
                        key,
                        value: 'N/A'
                    }
                }
            })

            return sums
        },

        onChangeTransactionDate (date) {
            this.model.as_of_date = date
        },
        async fetchData (attributes) {
            this.loading = true
            this.errors = new Errors()
            this.$store
                .dispatch('report/summaryReportPensionFundAndInsurance/fetch', {
                    as_of_date: this.as_of_date,
                    branch_id: this.branch_id,
                    ...attributes
                })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        exportPdf (attributes) {
            this.showForm = true
            this.errors = new Errors()
            this.previewing = true
            this.$store
                .dispatch(
                    'report/summaryReportPensionFundAndInsurance/previewPdf',
                    {
                        as_of_date: this.as_of_date,
                        branch_id: this.branch_id,
                        ...attributes
                    }
                )
                .then(response => {
                    this.src = response.url
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({
                        type: 'error',
                        text: error.message
                    })
                })
                .finally(() => {
                    this.previewing = false
                })
        },
        exportExcel () {
            this.exporting = true
            this.errors = new Errors()

            this.$store
                .dispatch(
                    'report/summaryReportPensionFundAndInsurance/exportExcel',
                    {
                        as_of_date: this.as_of_date,
                        branch_id: this.branch_id
                    }
                )
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => (this.exporting = false))
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'Summary pension fund & Insurance'
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit(
            'report/summaryReportPensionFundAndInsurance/RESET_STATE'
        )
        next()
    },
    watch: {
        branch_id: debounce(function (value) {
            this.employee_id = []
            this.fetchResource({
                fnName: 'employeeProfile',
                branch_id: value
            })
        }, 500)
    }
}
</script>
<style scoped>
#container {
    width: 100%;
    height: 80vh;
    background: rgb(93, 94, 95);
    text-align: center;
}
#container i {
    margin-top: 40vh;
    color: white;
}
</style>
